import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'
import Section from '../../../components/dai/Section'
import CalloutCard from '../../../components/dai/CalloutCard'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import FAQ from '../../../components/dai/FAQ'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'

const DesktopOnly = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
`

const MobileOnly = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`

export default function FormAccessibilityTips() {
  return (
    <LayoutDAI showKeyInsights pagePath={{ parent: 'key-insights', child: 'form-accessibility-tips' }}>
      <SEO
        title="Form Accessibility Tips | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/key-insights/form-accessibility-tips/"
        desc="After scanning almost 2 million forms, we found that nearly 25% are inaccessible to people with disabilities."
        bannerUrl="https://images.prismic.io/audioeye-web/5c16ad75-e53e-44ab-adb3-744e65686f9a_form-accessibility-tips.png?auto=compress,format"
        imageAlt="Stylized illustration showing both accessible and inaccessible forms."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/key-insights/form-accessibility-tips/`}
      />
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          FORM ACCESSIBILITY TIPS
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Form Accessibility Tips">
          For people with disabilities, forms often mean extra friction.
        </Heading1>
        <Space height={64} />
        <Text>
          The world’s leading brands spend a lot of time figuring out how to reduce friction on their forms — and get
          more people to complete them.
        </Text>
        <Space height={32} />
        <Text>
          But for people with disabilities, missing or vague field labels can make filling out forms an unpleasant
          guessing game. Without clear, descriptive labels (that are also announced to screen reader users), it can be
          hard for people with visual or cognitive impairments to know what information to enter in each field.
        </Text>
        <Space height={32} />
        <Text>
          After scanning almost 2 million forms, we found that{' '}
          <BoldText>nearly 25% are inaccessible to people with disabilities</BoldText> — which can prevent them from
          making purchases, signing up for accounts, or requesting support.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns unorderedList>
          <Column size={12} justifySpaceBetween listItem>
            <Text>
              Example of an <BoldText>inaccessible</BoldText> form field:
            </Text>
            <Space height={16} />
            <StaticImage
              src="../../../images/dai/examples/form-inaccessible.png"
              alt="An empty input field with an error symbol to represent an inaccessible form field without a label."
            />
          </Column>
          <Column size={12} justifySpaceBetween listItem>
            <Text>
              Example of an <BoldText>accessible</BoldText> form field:
            </Text>
            <Space height={16} />
            <StaticImage
              src="../../../images/dai/examples/form-accessible.png"
              alt="An input field for Email Address with a checkmark to represent an accessible form field with a clear label."
            />
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <CalloutCard>
          <Heading2 heading2 className="asH3" color="purple800">
            1 in 4 forms are missing clear labels and instructions for people with disabilities.
          </Heading2>
          <Space height={32} />
          <Text bodyMedium color="purple800">
            Missing or non-descriptive form field labels is one of the most common accessibility issues. Of the roughly
            2 million forms we scanned,{' '}
            <BoldText>nearly 25% failed to provide descriptive labels for their form fields.</BoldText>
          </Text>
          <Space height={32} />
          <DesktopOnly>
            <StaticImage
              src="../../../images/dai/callout-card/1-4-form-desktop.png"
              alt="1 broken form and 3 forms with labels like Name and Email to represent 1 in 4  forms that are missing clear labels."
            />
          </DesktopOnly>
          <MobileOnly>
            <StaticImage
              src="../../../images/dai/callout-card/1-4-form-mobile.png"
              alt="1 broken form and 3 forms with labels like Name and Email to represent 1 in 4  forms that are missing clear labels."
            />
          </MobileOnly>
        </CalloutCard>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Heading2 heading2 className="asH3">
          Keyboard traps can prevent people from taking the next step with your business.
        </Heading2>
        <Space height={32} />
        <Text>
          Keyboard accessibility is another common accessibility issue for most organizations — and it affects not just
          general site navigation, but also key actions like filling out forms.
        </Text>
        <Space height={32} />
        <Text>
          Keyboard-only users should be able to tab between elements on a page (or form) without getting “trapped,”
          i.e., being unable to move forward or backwards.
        </Text>
        <Space height={32} />
        <Text>
          However,{' '}
          <BoldText>
            81% of domains tested had at least one page with keyboard functionality issues — and 56% of pages had at
            least one issue.
          </BoldText>
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <CalloutCard>
          <Text heading3 color="purple800">
            56% of pages scanned had at least 1 keyboard trap
          </Text>
          <Space height={32} />
          <Text bodyMedium color="purple800">
            Keyboard accessibility issues can prevent people with visual and motor impairments from jumping between
            sections of a page, clicking a link, or filling out a form.
          </Text>
          <Space height={32} />
          <DesktopOnly>
            <StaticImage
              src="../../../images/dai/callout-card/56-form-desktop.png"
              alt="56 traps with an error symbol and 44 enter keys from a keyboard to represent 56% of pages scanned had at least 1 keyboard trap."
            />
          </DesktopOnly>
          <MobileOnly>
            <StaticImage
              src="../../../images/dai/callout-card/56-form-mobile.png"
              alt="56 traps with an error symbol and 44 enter keys from a keyboard to represent 56% of pages scanned had at least 1 keyboard trap."
            />
          </MobileOnly>
        </CalloutCard>
      </Section>
      <Section paddingTop="md" paddingBottom="md" constraint="lg" backgroundColor="orange200">
        <FAQ
          questions={[
            {
              title: 'Don’t use color alone to convey information',
              text: 'Websites today often use color to communicate different statuses (think red to indicate an error or missing field, or green to indicate success). Although these color-based cues are useful for many people, they can also present challenges for the 300 million people globally who have some form of color vision deficiency.',
              subtext:
                'If you use color to indicate missing or required information, be sure to combine it with another element (such as an error message or symbol) to make sure that people who struggle to perceive color are not left behind.',
            },
            {
              title: 'Be careful with CAPTCHA',
              text: 'Most organizations today use CAPTCHA to filter out spam traffic. However, it’s important to provide an accessible CAPTCHA alternative — for example, you don’t want to force users to distinguish between letters, if they have a visual impairment, a cognitive impairment, or dyslexia.',
            },
            {
              title: 'Check for keyboard traps',
              text: 'When evaluating the accessibility of your forms, make sure that a keyboard-only user can tab between focusable elements (i.e., elements that can handle keyboard input, such as an input field) using keyboard commands alone. ',
            },
          ]}
        >
          <Text largeCapsInter color="orange900">
            BEST PRACTICES
          </Text>
          <Space height={24} />
          <Heading2 heading2 className="asH3">
            Form accessibility tips
          </Heading2>
          <Space height={24} />
        </FAQ>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/key-insights/link-accessibility-tips/"
        previousPageText="Link Accessibility Tips"
        nextPageLink="/digital-accessibility-index/industry-reports/"
        nextPageText="Industry Reports"
      />
    </LayoutDAI>
  )
}
